<template>
  <section class="tiss-container">
    <GuidesReportFilter v-if="filter" class="no-print" @change="updateFilter" />

    <GuideReportTable
      :canDelete="canDelete"
      :headers="headers"
      :rows="rows"
      :getGuide="getGuide"
      :openHistoric="openHistoric"
      :openDocumentModal="openDocumentModal"
      :openDocumentListModal="openDocumentListModal"
      :addAndOpenGuide="addAndOpenGuide"
      :hasSavedInvoiceId="hasSavedInvoiceId"
      :filters="filters"
      :allSelectableGuides="allSelectableGuides"
      @onDeleteGuide="confirmDelete"
      @onOrdenate="handleOrder"
      @selected-guides="setSelectedGuides"
      @unselectedChecks="setUnselectedChecks"
      :type="type"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>

    <TissGuidesModalsContainer
      :patient="patient"
      :tissGuide="tissGuide"
      :clinicHealthPlan="clinicHealthPlan"
      :appointmentId="tissGuide.appointment_id"
      :getLastTissGuide="getLastTissGuide"
      :deleteTissGuide="deleteSusGuide"
      :clearTissGuide="clearTissGuide"
      :required_fields="tissRequiredCustomField"
      :getClinicHealthPlan="getClinicHealthPlan"
      :setTissGuide="setTissGuide"
      :getGuide="getGuide"
      :susGuide="susGuide"
      isSus
    />

    <TissGuideFilesContainer
      :file="file"
      :tissGuide="tissGuide"
      :files="files"
      :tissGuides="rows"
      :openDocumentModal="openDocumentModal"
      :openEditFileModal="openEditFileModal"
      :clearFile="clearFile"
      @hide-file-list-modal="hideFileListModal"
    />

    <TissGuideAuditModal :tissGuideAudits="tissGuideAudits" />

    <NewSusGuideModalVue
      :patient="patient"
      :healthPlan="healthPlan"
      :disabledHealthPlan="canEditHealthPlan"
      @createGuideSus="createGuideSus"
      @setClinicHealthPlanToPending="setClinicHealthPlan"
    />

    <SetBatchModal 
      :selectedGuides="selectedGuides" 
      :type="type" 
      :susInformation="susInformation"
      @onSave="onSave" 
    />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { parseNumberToMoney, parseMoneyToNumber } from '@/utils/moneyHelper'
import { handleGuideUpdate } from '@/utils/invoicingSusHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'
import api from '@/modules/sus/api'

export default {
  name: 'GuideReportContainer',
  props: {
    filter: {
      type: Boolean,
      default: true
    },
    rowsProps: {
      type: Array,
      default: () => []
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    type: String
  },
  components: {
    GuidesReportFilter: () =>
      import('@/components/Sus/Generic/Filters/GuidesReportFilter.vue'),
    GuideReportTable: () =>
      import('@/modules/sus/manageGuides/components/GuideReportTable'),
    NewSusGuideModalVue: () =>
      import('@/components/Sus/Modal/NewSusGuideModal.vue'),
    SetBatchModal: () =>
      import('@/components/Sus/Modal/SetBatchModal.vue'),
    TissGuideAuditModal: () =>
      import('@/components/Sus/Modal/TissGuideAuditModal.vue'),
    TissGuideFilesContainer: () =>
      import('@/layouts/InvoicingTiss/TissGuide/TissGuideFilesContainer.vue'),
    TissGuidesModalsContainer: () =>
      import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue')
  },
  data() {
    return {
      guideType: '',
      clinic: getCurrentClinic(),
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      tissGuide: {},
      clinicHealthPlan: {},
      tissGuideAudits: [],
      tissRequiredCustomField: {},
      file: {},
      files: [],
      patient: {},
      rows: this.rowsProps.length ? this.rowsProps : [],
      headers: [],
      filters: {},
      allSelectableGuides: [],
      selectedGuides: [],
      hasSavedInvoiceId: false,
      healthPlan: null,
      canEditHealthPlan: null,
      setClinicHealthPlan: false,
      susInformation: null,
      isSus: true,
      susGuide: null
    }
  },
  async created() {
    await this.getGuides();
    this.getHeader();
  },
  computed: {
    ...mapState('manageGuidesSus', ['requiredFields']),
  },
  methods: {
    getHeader(){
      const headers = this.type === 'APAC' ?
        [
          'checkbox',
          'Situação',
          'Data',
          'Paciente',
          'N° Autoriz.',
          'Profissional',
          'Lote',
          'N° Guia',
          'Faturado',
          'Recebido'
        ]
        : [
          'checkbox',
          'Situação',
          'Data',
          'Paciente',
          'N° Autoriz.',
          'Profissional',
          'Lote',
          'N° Guia',
          'Folha',
          'Faturado',
          'Recebido'
        ];

      if(this.requiredFields.guide_conference){
        headers.push('Conferido');
      }

      headers.push('');
      
      this.headers = headers;
    },
    parseNumberToMoney,
    parseMoneyToNumber,
    closeTissGuideModal() {
      this.clearTissGuide()
      this.$bvModal.hide('new-tiss-guide-modal')
    },
    createGuideSus(values) {
      this.susGuide = {};
      this.patient = values.susGuide.patient
    },
    getGuide(row, type){
      this.susGuide = row
      this.susGuide.clinic = this.clinic
      type === 'APAC' ? 
        this.$bvModal.show('apac-sus-guide-modal'):
        this.$bvModal.show('bpa-sus-guide-modal')
    },
    setSelectedGuides(guides) {
      this.selectedGuides = guides
      this.$emit('selected-guides', guides)
    },
    onSave() {
      this.getGuides()
      this.hasSavedInvoiceId = true
    },
    setUnselectedChecks() {
      this.hasSavedInvoiceId = false
    },
    addAndOpenGuide(tissGuide) {
      this.rows.unshift(tissGuide)
      this.getGuide(tissGuide)
    },
    async findByGuideIdReport(id) {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.findByGuideIdReport(id)
        return data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async findTissGuideByIdToReport(id) {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.findTissGuideByIdToReport(id)
        return data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getClinicHealthPlan(invoicingTissSettingId) {
      if (!this.clinic.id || !invoicingTissSettingId) return
      try {
        const { data } = await this.api.findByClinicIdAndInvoicingTissSettingId(
          this.clinic.id,
          invoicingTissSettingId
        )
        this.clinicHealthPlan = data
        this.tissRequiredCustomField =
          this.clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getClinicHealthPlanByPatient(patientHealthPlanId) {
      if (!this.clinic.id || !patientHealthPlanId) return
      try {
        const { data } = await this.api.getClinicHealthPlanByHealthId(
          this.clinic.id,
          patientHealthPlanId,
          false
        )
        if (!data) {
          this.$toast.warning('Convênio selecionado não vinculado a clínica')
          return
        }
        this.clinicHealthPlan = data

        this.tissRequiredCustomField =
          this.clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async updateFilter(newFilter) {
      this.filters = newFilter
      this.currentPage = 1
      await this.getGuides()
      if(this.filters.healthPlan){
        this.api.getGuidesByHealthPlanId(
          this.filters.healthPlan
        ).then(res => {
          this.allSelectableGuides = res.data
        }).catch(error => {
          this.$toast.error(error.message)
        })
      }
    },
    async getGuides() {
      const isLoading = this.$loading.show()
      try {
        this.filters.type = this.type
        this.filters.page = this.currentPage

        const { data } = await api.getSusGuides(
          this.clinic.id,
          this.currentPage,
          this.filters
        )
        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.totalItems = data.total
        this.rows = data.data
        if(this.currentPage === 1) this.getGuidesIds()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getGuidesIds() {
      const isLoading = this.$loading.show()
      try {
        this.filters.type = this.type
        this.filters.page = this.currentPage

        const { data } = await api.getSusGuideIds(
          this.clinic.id,
          this.filters
        )
        this.allSelectableGuides = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },

    openDocumentModal(tissGuide) {
      this.tissGuide = tissGuide
      this.$bvModal.show('tiss-guide-file-modal')
    },
    async getTissGuideFiles(tissGuideId) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideFiles(tissGuideId)
        return response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async openHistoric(tissGuideId) {
      await this.getTissGuideAudits(tissGuideId)
      this.$bvModal.show('tiss-guide-audit-modal')
    },
    async getTissGuideAudits(tissGuideId) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideAudits(tissGuideId)
        this.tissGuideAudits = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async openDocumentListModal(tissGuide) {
      this.tissGuide = tissGuide
      this.files = await this.getTissGuideFiles(tissGuide.id)
      this.$bvModal.show('tiss-guide-file-list-modal')
    },
    openEditFileModal(file) {
      this.file = file
      this.$bvModal.show('tiss-guide-file-modal')
    },
    openModalUpdateTiss({ tissGuide, type }) {
      this.tissGuide = tissGuide
      this.guide_type = type
      this.handleGuide()
    },
    clearFile() {
      this.file = {}
    },
    clearTissGuide() {
      this.tissGuide = {}
    },
    hideFileListModal(files) {
      if (files.length) {
        this.$set(this.tissGuide, 'files_count', files.length)
      } else {
        this.$set(this.tissGuide, 'files_count', 0)
      }
    },
    handleGuide(type) {
      const modal = handleGuideUpdate(type)
      this.$bvModal.show(modal)
    },
    async confirmDelete(susGuide) {
      await this.deleteSusGuide(susGuide)
    },
    async deleteSusGuide(susGuide, decrease = false) {
      const isLoading = this.$loading.show()
      try {
        await api.deleteSusGuide(susGuide.id, decrease)
        this.$toast.success('Guia removida com sucesso!')
        this.rows.splice(
          this.rows.findIndex(row => row.id === susGuide.id),
          1
        )
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    setTissGuide(tissGuide) {
      this.tissGuide = null
      this.tissGuide = tissGuide
    },
    handleOrder(value) {
      this.$set(this.filters, 'order', value)
      this.updateFilter(this.filters)
    },
    async getLastTissGuide(data) {
      await this.getGuides();
    },
  },
  watch: {
    currentPage: {
      handler: async function () {
        await this.getGuides()
      }
    },
    rowsProps(value) {
      this.rows = value
    }
  }
}
</script>
